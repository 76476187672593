<template>
<div class="action-block">
  <div class="common-action dark-gray" @click="showModal">
    <i :class="block.icon"></i> {{  block.name  }}
  </div>

  <vuestic-modal
    :isOpen="isShowModal"
    @cancel="isShowModal = false"
    :okShown="false"
    :cancelShown="false"
    :force="true"
    cancelClass="btn btn-danger"
  >
    <span slot="title" class="text-primary font-weight-bold">{{ block.description }}</span>
    <ValidationObserver 
      v-slot="{ handleSubmit, invalid, dirty }"
      ref="sendMessageActionForm"
    >
      <form @submit.prevent="handleSubmit(save)" class="sendMessageActionForm">
        <div class="row">
          <div class="col-12 mb-4">
            <text-input v-model="block.name"></text-input>
          </div>
          <div class="col-md-12">
            <label class="input-label">Tags</label>
            <tag-selector
              :show-label="false"
              v-model="tagSelected"
            ></tag-selector>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-primary" :disabled="invalid || processing || !allowSave" style="min-width: 80px;">
              <span>Save</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-modal>
</div>
</template>

<script>
import TagSelector from '../../../../common/TagSelector'

export default {
  components: { TagSelector },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      loading: false,
      processing: false,
      isShowModal: false,
      tags: [],
      tagSelected: [],
    }
  },

  mounted() {
    if (this.block.isNew) {
      this.block.isNew = false
      this.showModal()
    }
  },

  computed: {
    allowSave() {
      return this.tagSelected.length
    }
  },

  methods: {
    initValues() {
      let optionTags = this.block.options.hasOwnProperty('tags') ? this.block.options.tags : []
      this.tagSelected = this.tags.filter(item => {
        return optionTags.find(tagId => tagId == item.id)
      })
    },

    save() {
      if (!this.tagSelected.length)
      {
        Vue.$toast.open({
          message: 'Please select at least one tag.',
          type: 'error',
          duration: 3000,
        })
        return false;
      }
      this.block.options.tags = this.tagSelected.map(item => item.id)
      this.isShowModal = false
    },

    showModal() {
      this.loadTags();
      this.isShowModal = true
    },
    
    loadTags() {
      this.$store.dispatch('tag/all').then((res) => {
        this.tags = res
        this.initValues();
      });
    }
  }
}
</script>

<style lang="scss" scoped>
</style>